import React from 'react'
import Seo from '../../components/Seo'
import BigTitle from '../../components/BigTitle'
import ContactSection from '../../components/ContactSection'
import DefaultSection from '../../components/DefaultSection'
import Article from '../../components/Article'
import TeaserSection from '../../components/TeaserSection'
import TeaserHeader from '../../components/TeaserHeader'
import IconTeaser from '../../components/IconTeaser'
import NavigationLink from '../../components/NavigationLink'

const Page = () => {
  return (
    <React.Fragment>
      <Seo title="Kooperationen"
           description="BUSCHMAIS pflegt seit vielen Jahren eine enge Zusammenarbeit mit Hochschulen und Forschungseinrichtungen. Lernen Sie unsere gemeinsamen Projekte kennen. "
      />

      <BigTitle title="Kooperationen" className="mask-black" textInverted={true}
                imageRelativePath={'Titelbild_Unternehmen.jpg'}
                menuInverted={true} logoInverted={true}>Wir finden, dass vor allem der Förderung des IT-Nachwuchses im
        Standort Sachsen große Bedeutung zufällt. Deshalb pflegen wir seit vielen Jahren eine enge Zusammenarbeit mit
        Hochschulen und Forschungseinrichtungen.</BigTitle>

      <DefaultSection className="bg-level-1">
        <IconTeaser imageRelativePath={'Logo_Praxispartner_BA-Dresden.png'}
                    iconImageAlt="Logo der Berufsakademie Dresden">
          <h2>Berufsakademie Dresden</h2>
          <p>Die Berufsakademie in Dresden bietet Studieninteressierten ein vielseitiges, modernes und an
            den Bedarfen der Wirtschaft ausgerichtetes Studienangebot in wirtschaftswissenschaftlichen,
            informationstechnischen und ingenieurwissenschaftlich-technischen Studiengängen. Als renommierte staatliche
            Berufsakademie bieten wir gemeinsam mit über 1.000 anerkannten Praxispartnern ein duales Studium an, das
            sich auszahlt! Studierende absolvieren ein dreijähriges wissenschaftliches Studium im Wechsel zwischen
            Theorie und Praxis.
          </p>
        </IconTeaser>
      </DefaultSection>
      <DefaultSection>
        <Article
        ><h3>Unsere Zusammenarbeit mit der Berufsakademie Dresden</h3>
          <p>Als langjähriger Praxispartner der
            Berufsakademie Sachsen, Staatliche Studienakademie Dresden (BA Dresden) sind wir stetig dabei, junge Talente
            zu entdecken und
            während ihrer akademischen Ausbildung mit praxisrelevanten Erfahrungen zu unterstützen. Das duale Studium an
            der Berufsakademie bietet Studierenden mit BUSCHMAIS als Praxispartner die Möglichkeit, detaillierte und
            intensive Einblicke in spannende Projekte mit renommierten Kunden zu erhalten, sich kreativ und technisch
            versiert mit eigenen Konzepten einzubringen und sich dabei in unserem aufgeschlossenen Team mit Fachwissen
            und Engagement zu beweisen. Unser Anspruch ist es, das im Studium erlernte Grundlagenwissen nachhaltig zu
            stärken und unseren BA-Studierenden ein Verständnis für die Komplexität der IT-Branche mit auf den Weg zu
            geben.
          </p>
          <p>
            Sie möchten mehr über das berufsbegleitende Studium an der Berufsakademie Dresden erfahren und haben
            Interesse mit BUSCHMAIS als Praxispartner in ein Studium der Wirtschaftsinformatik zu starten? Dann
            informieren Sie sich gern auf der Webseite der <NavigationLink to="https://www.ba-dresden.de"
                                                                           ariaLabel="Link zur Webseite der Berufsakademie Dresden"
                                                                           asTextLink="true">BA
            Dresden</NavigationLink> oder direkt in
            unseren <NavigationLink to="/karriere/#StellenangeboteAktuell"
                                    ariaLabel="Unsere Stellenangebote"
                                    asTextLink> Stellenangeboten</NavigationLink>.
          </p>
        </Article>
      </DefaultSection>

      <DefaultSection className="bg-level-1">
        <IconTeaser imageRelativePath={'TUD_Logo_schwarz.png'} iconImageAlt="Logo der Technischen Universität Dresden">
          <h2>Technische
            Universität Dresden</h2><p>
          Die TU Dresden ist eine der größten Technischen Universitäten in Deutschland und eine der führenden und
          dynamischsten Hochschulen in Deutschland. Mit 18 Fakultäten in fünf Bereichen offeriert sie ein
          weitgefächertes Angebot aus 121 Studiengängen und deckt ein breites Forschungs-Spektrum ab. Ihre Schwerpunkte
          Biomedizin und Bioengineering, Materialwissenschaften, Informationstechnik und Mikroelektronik sowie Energie
          und Umwelt gelten bundes- und europaweit als vorbildlich.

          Seit 2012 gehört die TUD zu den elf deutschen Exzellenz-Universitäten.</p></IconTeaser>
      </DefaultSection>
      <DefaultSection>
        <Article><h3>Unsere Zusammenarbeit mit der TU Dresden</h3>
          <p>Mit der TU Dresden verbinden wir eine Vielzahl erfolgreicher
            Kooperationen. Gemeinsam mit der <NavigationLink
              to="https://tu-dresden.de/ing/informatik/smt/st/die-professur" ariaLabel="Link zur Webseite der Professur
            für Softwaretechnologie der Fakultät Informatik der TU Dresden" asTextLink="true">Professur
              für Softwaretechnologie der Fakultät Informatik</NavigationLink> konnten wir in den letzten Jahren in
            verschiedenen
            Bereichen nachhaltig zusammen arbeiten. Mit der Betreuung des jährlichen Softwarepraktikums hat BUSCHMAIS
            die
            Chance, noch während des Studiums mit Studierenden der
            Informatikstudiengänge zu interagieren und ihnen Einblicke in unsere Arbeitsweise zu geben.</p>
          <p>Im Wintersemester 2018/19 erstellten z. B. fünf Studierende mit unserem Kollegen Stephan Pirnbaum eine
            Webanwendung zur Aufzeichnung von Arbeitszeiten
            im Kontext des Projektgeschäfts. Dabei wurde die gesamte Bandbreite eines Softwareprojektes von der
            Anforderungsanalyse über Design und Implementierung bis hin zur Präsentation der Anwendung abgedeckt.
            Besonders freut uns, dass einer der Teilnehmer seit Sommer 2019 als Werkstudent bei uns tätig ist.</p>

          <p> Ebenfalls bietet die Professur für Softwaretechnologie regelmäßig die Ringvorlesung "Softwareentwicklung
            in der industriellen Praxis", organisiert durch Frau Dr. Birgit Demuth, an.
            In der Vergangenheit konnte unser Kollege Dirk Mahler bereits mit dem Vortrag „Miteinander, Nebeneinander,
            Durcheinander - Kommunikation und Koordination in Softwareprojekten“
            eine solche Vorlesung halten. Auch im Wintersemester 2019/20 ist BUSCHMAIS mit einem Beitrag von Stephan
            Pirnbaum zum Thema Software Analytics vertreten.</p>

        </Article>
      </DefaultSection>
      <DefaultSection className="bg-level-1">
        <IconTeaser imageRelativePath={'gff_logo_RGB_150dpi.png'}
                    iconImageAlt="Logo der Gesellschaft von Freunden und Förderern der TU Dresden e. V.">
          <h2>Gesellschaft von Freunden und Förderern der TU Dresden e. V.</h2><p>Die Gesellschaft von Freunden und
          Förderern der TU Dresden e. V. wurde 1991 anlässlich ihres 70. Gründungsjubiläums wieder ins Leben gerufen.
          Durch die Arbeit des Vereins sollen der Dialog zwischen Wirtschaft und Wissenschaft gefördert und intensive
          Beziehungen zu allen Bereichen der Gesellschaft geknüpft werden. </p></IconTeaser>
      </DefaultSection>
      <DefaultSection>
        <Article>
          <p> Über den Tellerrand der Informatik hinaus sind wir Mitglied in der <NavigationLink
            to="https://tu-dresden.de/tu-dresden/profil/freunde-foerderer/gff" ariaLabel="Link zur Webseite der Gesellschaft von
            Freunden und Förderern der TU Dresden" asTextLink="true">Gesellschaft von
            Freunden und Förderern der TU Dresden e. V.</NavigationLink>,
            und möchten damit zu einem wirkungsvollen Dialog zwischen Wirtschaft und Wissenschaft beitragen. Der Verein
            unterstützt jährlich mehr als 300 Projekte, welche vor allem die Förderung von Studierenden und
            Nachwuchswissenschaftlern/-innen in den Fokus rücken. Ein Zweck, den wir sehr gern fördern!</p>
        </Article>
      </DefaultSection>

      <DefaultSection className="bg-level-1">
        <IconTeaser imageRelativePath={'Logo-Uni-Leipzig.png'} iconImageAlt="Logo der Universität Leipzig">
          <h2>Universität Leipzig</h2>
          <p>Die Universität gehört zu den ältesten Universitäten Europas. Heute gehört sie als Mitglied im
            Verbund der German U15 zu den großen, forschungsstarken und medizinführenden Universitäten in Deutschland.
            Als
            weltoffene, moderne Volluniversität bietet sie über 150 Studiengänge von den Naturwissenschaften über Jura
            und
            Medizin bis hin zu zahlreichen Geistes- und Sozialwissenschaften an. Ihre Forschungsstärken bündelt sie in
            den
            drei strategischen Forschungsfeldern "Veränderte Ordnungen in einer globalisierten Welt", "Intelligente
            Methoden und Materialien" und "Nachhaltige Grundlagen für Leben und Gesundheit".
          </p></IconTeaser>
      </DefaultSection>
      <DefaultSection>
        <Article><h3>Unsere Zusammenarbeit mit der Universität Leipzig</h3><p>BUSCHMAIS pflegt seit 2017 eine enge und
          fruchtbare Kooperation mit der Universität Leipzig. Das
          zeigt sich besonders in den Ergebnissen der Zusammenarbeit mit Herrn Dr. Richard Müller und
          der <NavigationLink to="https://www.wifa.uni-leipzig.de/iwi/" ariaLabel="Link zur Webseite der Professur für
            Softwareentwicklung des Instituts für Wirtschaftsinformatik" asTextLink="true">Professur für
            Softwareentwicklung des Instituts für Wirtschaftsinformatik </NavigationLink> auf dem
          Gebiet der Analyse und Visualisierung von Software-Systemen. Ziel ist es, das Verständnis von Strukturen
          komplexer, über viele Jahre gewachsener Anwendungen maßgeblich zu erleichtern sowie Schwachstellen aufzudecken
          und zu bewerten. Das in unserem Hause entwickelte <NavigationLink to="/unternehmen/innovation"
                                                                            ariaLabel="Link zur Seite Innovation"
                                                                            asTextLink="true">Open-Source-Werkzeug
            jQAssistant</NavigationLink> stellt dabei
          die Basis zur Erfassung, Aufbereitung und Visualisierung relevanter Informationen dar.</p>

          <blockquote className="text-brand"><p>BUSCHMAIS ist ein unverzichtbarer Partner im Rahmen unserer
            anwendungsorientierten Forschung geworden. Gemeinsam erörtern wir relevante Problemstellungen und prüfen
            neue Ideen und Werkzeuge hinsichtlich ihrer Praxistauglichkeit. <br/>
            <em className="text-muted"><small> Dr. Richard Müller, Universität Leipzig</small>
            </em>
          </p>
          </blockquote>

          <p>
            Die ersten Früchte trug der Austausch im Rahmen einer Master-Arbeit eines engagierten Studierenden, in der
            ein
            erweiterbares Dashboard (<NavigationLink to="https://github.com/softvis-research/jqa-dashboard"
                                                     ariaLabel="Link zum Github-Seite des Projektes jQA Dashboard"
                                                     asTextLink="true">Projekt
            unter GitHub</NavigationLink>) basierend auf jQAssistant
            konzipiert und entwickelt wurde. Das Dashboard visualisiert Metriken, Issues oder Hotspots und steht unter
            einer Open-Source-Lizenz zur Verfügung. Auf dieser Basis konnte in einem gemeinsamen NIER-Paper (New Ideas
            And
            Emerging Results) der offene und erweiterbare Technologiestack bestehend aus jQAssistant, Neo4j und dem
            Dashboard entwickelt werden. Präsentiert wurde das Paper <NavigationLink
            to="http://home.uni-leipzig.de/svis/file/repository/vissoft2018_nier_paper.pdf"
            ariaLabel="Link zum NIER-Paper als PDF" asTextLink="true">"Towards an Open Source
            Stack to Create a Unified
            Data Source for Software Analysis and Visualization“(PDF)</NavigationLink> als Konferenzbeitrag bei der
            VISSOFT 2018 in
            Madrid.</p>
          <p>
            Das Seminar "Softwarevisualisierung" im Rahmen des Informatik-Studiums bietet Studierenden erste praxisnahe
            Einblicke in das Thema der Analyse und Visualisierung von Software-Systemen. Zu dessen
            Einführungsveranstaltung geben BUSCHMAIS-Kollegen einen Überblick über Einsatzgebiete von jQAssistant und
            unterstützen anschließend bei der Realisierung von Plugins bzw. Visualisierungen. Aufbauend auf den
            positiven
            Erfahrungen wurde gemeinsam im Rahmen des Google Summer of Code 2019 (GSoC) ein studentisches Projekt zur
            Erfassung und Analyse von Issues in JIRA-Systemen realisiert.</p>
          <p>
            Als ein weiteres Highlight ist die im September 2019 erstmalig angebotene internationale StiL-Sommerschule
            "Visual Software Analytics - Software Comprehension through Analysis and Visualization" hervorzuheben. Diese
            Veranstaltung bot
            Studierenden die Möglichkeit, Vorträge und Workshops von international anerkannten Wissenschaftlern und
            Experten aus renommierten IT-Unternehmen zu besuchen und einen außeruniversitären Wissensaustausch zu
            pflegen.
            Unser Kollege Dirk Mahler unterstützte die Sommerschule mit einem Vortrag und einer praktischen Einführung
            zum
            Thema "Extracting and Analyzing Software with jQAssistant".</p>
          <p>
            BUSCHMAIS hat mit der Universität Leipzig einen kompetenten Forschungspartner gefunden, der wertvolle
            inhaltliche Impulse für die Weiterentwicklung unserer Arbeit im Umfeld der Architekturberatung liefert. Für
            die Universität Leipzig ist die praxisnahe Forschung von hohem Wert, um relevante Problemstellungen
            identifizieren und neue Ansätze unmittelbar in unserem Tagesgeschäft evaluieren zu können.</p></Article>
      </DefaultSection>


      <TeaserSection className="bg-level-2">
        <TeaserHeader title="Werde Teil des Teams" linkTo={'/karriere/'} linkTitle={'Karriere'}>
          <p className="mb-5">Wir sind stets daran interessiert, Abschlussarbeiten von
            Studierenden zu betreuen. Bereits die gemeinsame Themenfindung gestaltet sich als toller Austausch, bei dem
            alle Seiten neue Blickwinkel und Ansätze kennen lernen können.
            Du verfolgst ein Studium im Informatik-Bereich und hast Lust, Deine Abschlussarbeit unternehmensbegleitend
            zu schreiben? Vielleicht bist Du auch auf der Suche nach einer Werkstudententätigkeit? Wir freuen uns auf
            Deine Ideen!
          </p>
        </TeaserHeader>
      </TeaserSection>

      <ContactSection/>
    </React.Fragment>
  )
}

export default Page
